import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Capacitor } from '@capacitor/core';

import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import {
  getFirestore,
  provideFirestore,
  enableMultiTabIndexedDbPersistence,
} from "@angular/fire/firestore";
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from "@angular/fire/analytics";

import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from "@angular/fire/auth";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthService } from "./services/auth.service";
import { BarPageModule } from "./pages/settings/bar/bar.module";

import { LetDirective, PushPipe } from "@ngrx/component";

// import * as Sentry from 'sentry-cordova';
import * as Sentry from "@sentry/browser";
// import { RewriteFrames } from '@sentry/integrations'
import { SentryIonicErrorHandler } from "./shared-modules/sentry-ionic-error-handler";
import { ServiceWorkerModule } from "@angular/service-worker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import packageJson from "../../package.json";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule, routerReducer } from "@ngrx/router-store"
import * as fromUser from "./state/user/reducer";
import * as fromUnits from "./state/units/reducer";
import * as fromBar from "./state/bar/reducer";
import * as fromBarSettings from "./state/bar-settings/reducer";
import * as fromKeg from "./state/keg/reducer";
import * as fromTap from "./state/tap/reducer";
import * as fromPlaato from "./state/plaato/reducer";
import * as fromKegtron from "./state/kegtron/reducer";
import * as fromCustomKegMon from "./state/custom_keg_mon/reducer";
import * as fromBeverage from "./state/beverage/reducer";
import * as fromBeerStyle from "./state/beer-style/reducer";
import * as fromExternalApiSettings from "./state/external-api-settings/reducer";
import * as fromServingSize from "./state/serving-size/reducer";
import * as fromStatistics from "./state/statistics/reducer";
import * as fromCheers from "./state/cheers/reducer";
import * as fromSubscription from "./state/subscription/reducer";
import { UnitsEffects } from "./state/units/effects";
import { BarEffects } from "./state/bar/effects";
import { StatisticEffects } from "./state/statistics/effects";
import { BeverageEffects } from "./state/beverage/effects";
import { BeerStyleEffects } from "./state/beer-style/effects";
import { TapEffects } from "./state/tap/effects";
import { UserEffects } from "./state/user/effects";
import { KegEffects } from "./state/keg/effects";
import { ServingSizeEffects } from "./state/serving-size/effects";
import { LocationEffects } from "./state/location/effects";
import * as fromLoation from "./state/location/reducer";
import { NgParticlesModule } from "ng-particles";
import { PlaatoEffects } from "./state/plaato/effects";
import { KegtronEffects } from "./state/kegtron/effects";
import { CustomKegMonEffects } from "./state/custom_keg_mon/effects";
import { ExternalApiSettingsEffects } from "./state/external-api-settings/effects";
import { SubscriptionEffects } from "./state/subscription/effects";
import { BarSettingsEffects } from "./state/bar-settings/effects";
import * as fromPlaatoStatus from "./state/plaato-status/";
import * as fromFont from "./state/font/";
import * as fromOnTapAdvanced from 'app/state/on_tap_advanced';
import * as fromOnTapTemplate from 'app/state/ontap-template';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
//import { NgrxRouterStoreModule } from "./state/router/ngrx-router.module";
import * as fromNotification from './state/notification';
import * as fromStripeCustomer from './state/stripeCustomer';
import { metaReducers } from "./state/app.state";
import { Effects } from './state/stripeProduct/effects';
import * as fromStripeProduct from 'app/state/stripeProduct';
import * as fromStripePrice from 'app/state/stripePrice';
import * as fromStripeSubscription from 'app/state/stripeSubscription';

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.production ? "prod" : "dev",
  release: environment.sentry.projectName + "@" + packageJson.version,
  // integrations: [
  //   new RewriteFrames()
  // ],
});

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>(resolve => {
  resolvePersistenceEnabled = resolve;
});

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => {
      const auth = getAuth();
      // if (environment.useEmulators) {
      //   connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      // }
      return auth;
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    //provideFirebaseApp(() => initializeApp(environment.firebase)),

    provideFirestore(() => {
      const firestore = getFirestore();
      // if (environment.useEmulators) {
      //     connectFirestoreEmulator(firestore, 'localhost', 8080);
      // }
      enableMultiTabIndexedDbPersistence(firestore).then(
        () => resolvePersistenceEnabled(true),
        () => resolvePersistenceEnabled(false)
      );
      return firestore;
    }),


    // provideFirestore(() => {
    //   //const firestore = getFirestore();
    //   const firestore = initializeFirestore(getApp(), {
    //     experimentalForceLongPolling: true,
    //   });
    //   enableIndexedDbPersistence(firestore);
    //   return firestore;
    // }),
    //    provideAnalytics(() => getAnalytics()),
    // providePerformance(() => getPerformance()),
    // provideAuth(() => {
    //   const auth = getAuth();
    //   // if(environment.useEmulators) {
    //   //   connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: false })
    //   // }
    //   return auth;
    // }),
    // provideStorage(() => getStorage()),
    // provideFunctions(() => getFunctions(getApp(), "europe-west1")),
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BarPageModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true
    }),
    StoreModule.forFeature(fromUser.stateName, fromUser.reducer),
    StoreModule.forFeature(fromBar.stateName, fromBar.reducer),
    StoreModule.forFeature(fromBarSettings.stateName, fromBarSettings.reducer),
    StoreModule.forFeature(fromUnits.stateName, fromUnits.reducer),
    StoreModule.forFeature(fromBeverage.stateName, fromBeverage.reducer),
    StoreModule.forFeature(fromBeerStyle.stateName, fromBeerStyle.reducer),
    StoreModule.forFeature(fromLoation.stateName, fromLoation.reducer),
    StoreModule.forFeature(fromCheers.stateName, fromCheers.reducer),
    StoreModule.forFeature(fromServingSize.stateName, fromServingSize.reducer),
    StoreModule.forFeature(fromStatistics.stateName, fromStatistics.reducer),
    StoreModule.forFeature(fromKeg.stateName, fromKeg.reducer),
    StoreModule.forFeature(fromTap.stateName, fromTap.reducer),
    StoreModule.forFeature(fromPlaato.stateName, fromPlaato.reducer),
    StoreModule.forFeature(fromKegtron.stateName, fromKegtron.reducer),
    StoreModule.forFeature(fromCustomKegMon.stateName, fromCustomKegMon.reducer),
    StoreModule.forFeature(fromExternalApiSettings.stateName, fromExternalApiSettings.reducer),
    StoreModule.forFeature(fromSubscription.stateName, fromSubscription.reducer),
    StoreModule.forFeature(fromPlaatoStatus.stateName, fromPlaatoStatus.reducer),
    StoreModule.forFeature(fromFont.stateName, fromFont.reducer),
    StoreModule.forFeature(fromOnTapAdvanced.stateName, fromOnTapTemplate.reducer),
    StoreModule.forFeature(fromOnTapTemplate.stateName, fromOnTapTemplate.reducer),
    StoreModule.forFeature(fromNotification.stateName, fromNotification.reducer),
    StoreModule.forFeature(fromStripeCustomer.stateName, fromStripeCustomer.reducer),
    StoreModule.forFeature(fromStripeProduct.stateName, fromStripeProduct.reducer),
    StoreModule.forFeature(fromStripePrice.stateName, fromStripePrice.reducer),
    StoreModule.forFeature(fromStripeSubscription.stateName, fromStripeSubscription.reducer),

    EffectsModule.forFeature([
      UserEffects,
      BarEffects,
      BarSettingsEffects,
      UnitsEffects,
      BeverageEffects,
      BeerStyleEffects,
      LocationEffects,
      ServingSizeEffects,
      StatisticEffects,
      KegEffects,
      TapEffects,
      PlaatoEffects,
      KegtronEffects,
      CustomKegMonEffects,
      ExternalApiSettingsEffects,
      SubscriptionEffects,
      fromFont.Effects,
      fromOnTapAdvanced.Effects,
      fromOnTapTemplate.Effects,
      fromNotification.Effects,
      fromStripeCustomer.Effects,
      fromStripeProduct.Effects,
      // fromStripePrice.Effects,
      fromStripeSubscription.Effects
    ]),
    StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
    RouterModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    PushPipe,
    LetDirective,
    NgParticlesModule,

    provideFirebaseApp(() => {
      const app = initializeApp(environment.firebase);
      if (Capacitor.isNativePlatform() === true) {
        initializeAuth(app, {
          persistence: indexedDBLocalPersistence
        });
      }
      return app;
    }),



    // NgrxRouterStoreModule,

    //   provideAuth(() => getAuth()),
    //   provideAppCheck(() => {
    //     // TODO get a reCAPTCHA Enterprise here https://console.cloud.google.com/security/recaptcha?project=_
    //     const provider = new ReCaptchaEnterpriseProvider(environment.reCaptchaKey);
    //     return initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });
    //   }),
    //   //provideFirestore(() => getFirestore()),
    //   provideFunctions(() => getFunctions()),
    //   //provideMessaging(() => getMessaging())
    // )
  ],
  providers: [
    AuthService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    ScreenTrackingService,
    UserTrackingService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  declarations: [AppComponent],
})
export class AppModule { }

